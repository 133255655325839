
import { statusActions } from "../../../components/grid-views/status-actions";
import i18n from "@/plugins/i18n";
import {APIService} from "../../../services/api";

export const columns = [
  {
    label: '#',
    field: (value) => {
      return value.originalIndex + 1;
    },
    width: '2%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'orderNumber',
    field: 'orderNumber',
    width: '5%',
    sortable: false,
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'address',
    field: 'forms.address',
    formatRowValue: true,
    formatFn: (value) => {
      return value.forms.length>0 ? (value.forms[0].town ? `${value.forms[0].town}, ` : '') + value.forms[0].address : '';
    },
    width: '33%',
    sortable: false,
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'phone',
    field: 'forms.phone',
    formatRowValue: true,
    formatFn: (value) => {
      return value.forms.length>0 ? value.forms[0].phone : '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'manager',
    field: 'managers.id',
    filterField: 'managers.id',
    sortable: false,
    formatRowValue: true,
    formatFn: (value) => {
      return value.managers.length > 0 ? value.managers[0].title : '';
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: i18n.t('chooseManager'),
      filterValue: null,
      filterDropdownItems: [],
    },
  },
  {
    label: 'driver',
    field: 'drivers.id',
    sortable: false,
    formatRowValue: true,
    formatFn: (value) => {
      return value.drivers.length>0 ? value.drivers[0].title : '';
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: i18n.t('chooseDriver'),
      filterValue: null,
      filterDropdownItems: [],
    },
  },
  {
    label: 'paymentMethod',
    field: 'paymentMethods.id',
    sortable: false,
    formatRowValue: true,
    formatFn: (value) => {
      return value.paymentMethods.length>0 ? value.paymentMethods[0].title : '';
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: i18n.t('choosePaymentMethod'),
      filterValue: null,
      filterDropdownItems: [],
    },
  },
  {
    label: 'status',
    field: 'statuses.id',
    sortable: false,
    formatRowValue: true,
    formatFn: (value) => {
      return value.statuses.length > 0 ? value.statuses[0].title : '&nbsp;';
    },
    width: '12%',
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: i18n.t('chooseStatus'),
      filterValue: null,
      filterDropdownItems: [],
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      ...statusActions,
      {
        type: 'status',
        label: '',
        classCallback: (row) => {
          return row.statuses.length > 0 && ['considerated','during_delivery'].includes(row.statuses[0].token)
            ? `fa ${row.statuses[0].icon}` 
            : '';
        },
        action: {
          type: 'callback',
          callbackFunction: async (list, data, actionsParams, refresh, eventBus) => {
            const statuses = await APIService.get(`status?filter=token||$in||considerated,during_delivery,delivered`);
            const statusIndex = statuses.findIndex(status => status.id === data.statuses[0].id);
            let status;
            if (statusIndex > -1) {
              status = statusIndex < statuses.length - 2 ? statuses[statusIndex + 1] : statuses[statuses.length - 1];
            } else {
              status = statuses[0];
            }
            data.statuses[0] = status;
            try {
              const res = await APIService.put(APIService.getRestFullEntityUrl(actionsParams.url, data), data);
              if (!res) {
                throw new Error('Error on status save');
              }
              eventBus.$emit('alert', {
                type: 'success',
                text: i18n.t('statusWasSuccessfullySaved'),
              });
            } catch (e) {
              eventBus.$emit('alert', {
                type: 'danger',
                text: i18n.t('errorOnStatusSaving'),
              });
            }
            refresh();
          },
        },
      },
      {
        type: 'view',
        label: '',
        class: 'fa fa-eye',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {'id': data.id}
          },
          nameResolver: () => {
            return 'ViewOrder';
          }
        },
      },
      {
        type: 'print',
        label: '',
        class: 'fa fa-print',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {'id': data.id}
          },
          nameResolver: () => {
            return 'PrintOrder';
          }
        },
      },
    ]
  },
]
