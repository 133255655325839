<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('ordersList') }}</h2>
      <b-row class="mt-3">
        <b-col md="4" lg="3" sm="12">
          <date-picker
            v-model="date"
            :config="dateOptions"
          />
        </b-col>
        <b-col md="3" lg="2" sm="12">
          <b-button
            variant="primary"
            @click="searchOrders"
          >
            {{ $t('search') }}
          </b-button>
        </b-col>
        <b-col md="5" offset-lg="3" lg="4" sm="12">
          <b-dropdown :disabled="loadedOrders.length === 0" :text="`${$t('logisticsActions')} ${selectedOrdersText}`" variant="primary" class="pull-right">
            <b-dropdown-item :to="{name: 'OrdersPositionsList'}">
              {{ $t('OrdersPositionsList') }}
            </b-dropdown-item>
            <b-dropdown-item :to="{name: 'RecipientsList'}">
              {{ $t('RecipientsList') }}
            </b-dropdown-item>
            <b-dropdown-item :to="{name: 'OrdersPrint'}">
              {{ $t('OrdersPrint') }}
            </b-dropdown-item>
            <b-dropdown-item>
              <a 
                class="v-auth-href"
                v-auth-href 
                :href="ordersExportUrl"
              >
                {{ $t('ExcelRecipientsExport') }}
              </a>
            </b-dropdown-item>
            <b-dropdown-item>
              <a 
                class="v-auth-href"
                v-auth-href
                :href="positionsExportUrl"
              >
                {{ $t('ExcelPositionsExport') }}
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <grid-view-table
        id="logistic"
        ref="logistic"
        :columns="columns"
        :path="'order'"
        :extra-params="gridViewUrl"
        :enableCheckboxes="true"
        :rowStyleClassFn="rowStyleClassFn"
        :per-page="100"
        :default-sort="'createdAt,DESC'"
      />
      <b-row class="block-radios">
        <b-col sm="12" md="6" lg="4">
          <b-form-group :label="$t('driver')">
            <b-form-radio-group
              v-model="driver"
              :options="drivers"
              name="driver-options"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group :label="$t('payment')">
            <b-form-radio-group
              v-model="payment"
              :options="payments"
              name="payment-options"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col sm="12" md="6" lg="3">
          <b-button
            type="submit"
            block
            variant="primary"
            :disabled="(!driver && !payment) || selectedOrders.length === 0"
            @click.prevent="submit"
          >
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
      <br />
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/logistics';
  import datePicker from 'vue-bootstrap-datetimepicker';
  import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
  import { APIService } from '../../services/api';

  export default {
    name: 'LogisticsList',
    components: {
      GridViewTable,
      datePicker,
    },
    data: () => {
      return {
        columns,
        drivers: [],
        driver: null,
        payments: [],
        payment: null,
        date: null,
        dateOptions: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          locale: 'ru',
        }
      }
    },
    async beforeMount() {
      this.date = this.$store.getters.GET_LOGISTIC_DATE;
      try {
        const statuses = await APIService.get('status?sort=id,ASC');
        const managers = await APIService.get('manager?sort=title,ASC');
        const drivers = await APIService.get('driver?sort=title,ASC');
        const paymentMethods = await APIService.get('payment-method?sort=title,ASC');
        this.drivers = drivers.map(driver => {
          return {
            text: driver.title,
            value: driver,
          }
        });
        this.payments = paymentMethods.map(payment => {
          return {
            text: payment.title,
            value: payment,
          }
        });
        this.fillGridFilterSelectValues('drivers.id', drivers);
        this.fillGridFilterSelectValues('paymentMethods.id', paymentMethods);
        this.fillGridFilterSelectValues('statuses.id', statuses);
        this.fillGridFilterSelectValues('managers.id', managers);
      } catch (e) {
        console.error(e.toString());
      }
    },
    methods: {
      rowStyleClassFn(row) {
        return row.statuses.length>0 ? row.statuses[0].token : '';
      },
      fillGridFilterSelectValues(fieldName, optionValues) {
        const columnIndex = this.columns.findIndex(column => column.field === fieldName);
        if (columnIndex > -1) {
          this.columns[columnIndex].filterOptions.filterDropdownItems = optionValues.map(option => {
            return {
              text: option.title,
              value: option.id,
            }
          });
        }
      },
      searchOrders() {
        const date = moment(this.date, 'DD.MM.YYYY').toDate();
        this.$store.dispatch('UPDATE_LOGISTIC_DATE', date);
      },
      calculateDays() {
        const date = this.$store.getters.GET_LOGISTIC_DATE;
        const startDate = moment(date).startOf('day');
        const endDate = moment(startDate).endOf('day');
        return [startDate.format(), endDate.format()].map(dateTime => encodeURIComponent(dateTime));
      },
      async submit() {
        const orders = this.selectedOrders.map(order => {
          if (this.driver) {
            order.drivers = [this.driver];
          }
          if (this.payment) {
            order.paymentMethods = [this.payment];
          }
          return order;
        });
        try {
          for (const order of orders) {
            await APIService.patch(APIService.getRestFullEntityUrl('order', order), order);
          }
        } catch(e) {
          console.error(e);
        }
        await this.$refs.logistic.loadItems();
      }
    },
    computed: {
      grid() {
        return this.$store.getters.GET_GRID('logistic');
      },
      loadedOrders() {
        return this.grid ? this.grid.rows : [];
      },
      selectedOrders() {
        return this.grid ? this.grid.selectedRows : [];
      },
      selectedOrdersText() {
        if (this.loadedOrders.length > 0 && this.selectedOrders.length > 0) {
          return `${this.selectedOrders.length}/${this.loadedOrders.length}`; 
        }
        return '';
      },
      gridViewUrl() {
        const [startDate, endDate] = this.calculateDays();
        let url = '&join=forms&join=managers&join=statuses&join=drivers&join=paymentMethods&join=deliveryDates';
        url += '&filter=statuses.token||$in||considerated,during_delivery,delivered';
        return url + `&filter=deliveryDates.date||$between||${startDate},${endDate}`;
      },
      positionsExportUrl() {
        const ids = this.selectedOrders.map(order => order.id);
        const idsString = ids.length > 0 ? `?orders=${ids.join(',')}` : '';
        const [startDate, endDate] = this.calculateDays();
        return `${APIService.getBaseUrl()}export/positions/${startDate}/${endDate}${idsString}`;
      },
      ordersExportUrl() {
        const ids = this.selectedOrders.map(order => order.id);
        const idsString = ids.length > 0 ? `?orders=${ids.join(',')}` : '';
        const [startDate, endDate] = this.calculateDays();
        return `${APIService.getBaseUrl()}export/orders/${startDate}/${endDate}${idsString}`;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .block-radios {
    .custom-control-inline{
      width: 100%;
    }
  }
  .v-auth-href {
    color: #23282c;
    text-decoration: none;
  }
</style>
